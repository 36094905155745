<template>
  <div class="recruit">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="recruit-bach"
      @click.stop="back()"
    />
    <div class="recruit-li">
      <div class="recruit-li-title flex items-center flex-between">
        <div class="recruit-li-title-text">游戏特效师</div>
        <div class="flex items-center">
          <div class="recruit-li-title-text1">10k·13薪</div>
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/Vector2x.png"
            alt=""
            class="recruit-li-title-img"
            @click="unfold = !unfold"
            :style="unfold ? 'transform: rotate(180deg)' : ''"
          />
        </div>
      </div>
      <div class="recruit-li-text" v-if="unfold">
        <div class="recruit-li-text-title">一、岗位职责</div>
        <div>
          1. 负责游戏中各类特效的设计与制作，包括但不限于技能特效、场景特效、UI
          特效等。
        </div>
        <div>
          2.
          与美术团队的其他成员（如角色建模师、场景设计师等）紧密合作，确保特效风格与整体游戏美术风格一致。
        </div>
        <div>
          3.
          不断优化特效的性能，以保证在不同设备上都能流畅运行，同时不损失视觉效果。
        </div>
        <div>
          4.
          能够根据游戏策划和美术需求，提出创新性的特效设计方案，并高效地实现。
        </div>
        <div class="recruit-li-text-title1">二、任职要求</div>
        <div>
          1. 本科及以上学历，数字媒体艺术、动画、游戏设计等相关专业优先。
        </div>
        <div>
          2. 具有1年以上游戏特效制作经验，熟悉主流游戏引擎（如 Unity、Unreal
          等）的特效模块。
        </div>
        <div>
          3. 熟练掌握至少一种特效制作软件，如 3ds Max、Maya、After
          Effects、Houdini 等。
        </div>
        <div>
          4. 具备扎实的美术基础，对色彩、光影、动态有敏锐的观察力和表现力。
        </div>
        <div>
          5.
          能够独立完成特效资源的整合、导入和优化工作，对特效的性能和效率有良好的把控能力。
        </div>
        <div>
          6.
          有良好的团队合作精神和沟通能力，能够承受工作压力，按时保质完成任务。
        </div>
        <div>
          7.
          有较强的学习能力和创新精神，关注行业动态，能够不断提升自身的专业水平。
        </div>
        <div class="recruit-li-text-title1">三、加分项</div>
        <div>1. 有成功上线的游戏项目特效制作经验者优先。</div>
        <div>2. 具备手绘能力，能够独立绘制特效元素者优先。</div>
        <div>
          3. 对游戏玩法和用户体验有深入理解，能够根据游戏需求定制特效者优先。
        </div>
        <div class="recruit-li-text-title1">
          作品及简历投递邮箱：loki@bbp.bubbleplan.cn
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      unfold: false,
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.recruit {
  width: 100%;
  height: calc(100vh - 94px);
  background: #9a13a5;
  position: relative;
  overflow: auto;
  padding-top: 160px;
  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 74px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
  &-li {
    width: 1244px;
    margin: auto;
    &-title {
      width: 100%;
      height: 124px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/1244-153.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 0 57px;
      &-text {
        color: #ffffff;
        text-shadow: 2px 3px 2px #74077d;
        font-size: 24px;
      }
      &-text1 {
        color: #daff00;
        font-size: 24px;
        font-weight: 700;
      }
      &-img {
        width: 20px;
        height: 11px;
        margin-left: 27px;
      }
    }
    &-text {
      width: 100%;
      height: 893px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/Frame502x.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 31px 52px;
      color: #ffffff;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      // letter-spacing: 1.1px;
      &-title {
        margin-bottom: 30px;
      }
      &-title1 {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>